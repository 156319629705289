@import "@/styles/variables.scss";

.slide-enter-active,
.slide-leave-active {
  transition: margin-bottom 0.5s ease-out;
}

.slide-enter,
.slide-leave-to {
  margin-bottom: -200px;
}

.slide-enter-to,
.slide-leave {
  margin-bottom: 0px;
}

.error {
  color: $wAlert !important;
  label {
    color: $wAlert;
  }
  input,
  textarea {
    border: 1px $wAlert solid !important;
  }
  .field-input {
    border: 1px $wAlert solid !important;
  }
  .multiselect__tags {
    border: 1px $wAlert solid !important;
  }
}
.green {
  input {
    border: 1px $wGreenSucc solid !important;
  }
  .field-input {
    border: 1px $wGreenSucc solid !important;
  }
  .multiselect__tags {
    border: 1px $wGreenSucc solid !important;
  }
}
